/* You can add global styles to this file, and also import other style files */
@font-face {
	font-family: 'ecoicon';
	src:url('assets/fonts/fonts/ecoicons/ecoicon.eot');
	src:url('assets/fonts/fonts/ecoicons/ecoicon.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/fonts/ecoicons/ecoicon.woff') format('woff'),
		url('assets/fonts/fonts/ecoicons/ecoicon.ttf') format('truetype'),
		url('assets/fonts/fonts/ecoicons/ecoicon.svg#ecoicon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/*了解我們btn*/
.btn-sourcecode {
    color: #fff;
    background-color: #055684;
    border-color: #055684;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .btn-sourcecode:hover {
    color: #fff;
    background-color: #7DC6E8;
    border-color: #B8DEF2;
  }
  
  .btn-sourcecode:focus,
  .btn-sourcecode.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(6,65, 91, 0.5);
  }
  
  .btn-sourcecode.disabled,
  .btn-sourcecode:disabled {
    color: #fff;
    background-color: #055684;
    border-color: #055684;
  }
  
  .btn-sourcecode:not(:disabled):not(.disabled):active,
  .btn-sourcecode:not(:disabled):not(.disabled).active,
  .show>.btn-sourcecode.dropdown-toggle {
    color: #fff;
    background-color: #B8DEF2;
    border-color: #06587C;
  }
  
  .btn-sourcecode:not(:disabled):not(.disabled):active:focus,
  .btn-sourcecode:not(:disabled):not(.disabled).active:focus,
  .show>.btn-sourcecode.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(6, 65, 91, 0.5);
  }
  .m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }
  
  .m--9 {
    margin: -10rem !important;
  }
  
  .mt--9,
  .my--9 {
    margin-top: -10rem !important;
  }
  
  .mr--9,
  .mx--9 {
    margin-right: -10rem !important;
  }
  
  .mb--9,
  .my--9 {
    margin-bottom: -10rem !important;
  }
  
  .ml--9,
  .mx--9 {
    margin-left: -10rem !important;
  }
  
  .m--8 {
    margin: -8rem !important;
  }
  
  .mt--8,
  .my--8 {
    margin-top: -8rem !important;
  }
  
  .mr--8,
  .mx--8 {
    margin-right: -8rem !important;
  }
  
  .mb--8,
  .my--8 {
    margin-bottom: -8rem !important;
  }
  
  .ml--8,
  .mx--8 {
    margin-left: -8rem !important;
  }
  
  .m--7 {
    margin: -6rem !important;
  }
  
  .mt--7,
  .my--7 {
    margin-top: -6rem !important;
  }
  
  .mr--7,
  .mx--7 {
    margin-right: -6rem !important;
  }
  
  .mb--7,
  .my--7 {
    margin-bottom: -6rem !important;
  }
  
  .ml--7,
  .mx--7 {
    margin-left: -6rem !important;
  }
  
  .m--6 {
    margin: -4.5rem !important;
  }
  
  .mt--6,
  .my--6 {
    margin-top: -4.5rem !important;
  }
  
  .mr--6,
  .mx--6 {
    margin-right: -4.5rem !important;
  }
  
  .mb--6,
  .my--6 {
    margin-bottom: -4.5rem !important;
  }
  
  .ml--6,
  .mx--6 {
    margin-left: -4.5rem !important;
  }
  
  .m--5 {
    margin: -3rem !important;
  }
  
  .mt--5,
  .my--5 {
    margin-top: -3rem !important;
  }
  
  .mr--5,
  .mx--5 {
    margin-right: -3rem !important;
  }
  
  .mb--5,
  .my--5 {
    margin-bottom: -3rem !important;
  }
  
  .ml--5,
  .mx--5 {
    margin-left: -3rem !important;
  }
  
  .m--4 {
    margin: -1.5rem !important;
  }
  
  .mt--4,
  .my--4 {
    margin-top: -1.5rem !important;
  }
  
  .mr--4,
  .mx--4 {
    margin-right: -1.5rem !important;
  }
  
  .mb--4,
  .my--4 {
    margin-bottom: -1.5rem !important;
  }
  
  .ml--4,
  .mx--4 {
    margin-left: -1.5rem !important;
  }
  
  .m--3 {
    margin: -1rem !important;
  }
  
  .mt--3,
  .my--3 {
    margin-top: -1rem !important;
  }
  
  .mr--3,
  .mx--3 {
    margin-right: -1rem !important;
  }
  
  .mb--3,
  .my--3 {
    margin-bottom: -1rem !important;
  }
  
  .ml--3,
  .mx--3 {
    margin-left: -1rem !important;
  }
  
  .m--2 {
    margin: -0.5rem !important;
  }
  
  .mt--2,
  .my--2 {
    margin-top: -0.5rem !important;
  }
  
  .mr--2,
  .mx--2 {
    margin-right: -0.5rem !important;
  }
  
  .mb--2,
  .my--2 {
    margin-bottom: -0.5rem !important;
  }
  
  .ml--2,
  .mx--2 {
    margin-left: -0.5rem !important;
  }
  
  .m--1 {
    margin: -0.25rem !important;
  }
  
  .mt--1,
  .my--1 {
    margin-top: -0.25rem !important;
  }
  
  .mr--1,
  .mx--1 {
    margin-right: -0.25rem !important;
  }
  
  .mb--1,
  .my--1 {
    margin-bottom: -0.25rem !important;
  }
  
  .ml--1,
  .mx--1 {
    margin-left: -0.25rem !important;
  }
  
  .m-6 {
    margin: 4.5rem !important;
  }
  
  .mt-6,
  .my-6 {
    margin-top: 4.5rem !important;
  }
  
  .mr-6,
  .mx-6 {
    margin-right: 4.5rem !important;
  }
  
  .mb-6,
  .my-6 {
    margin-bottom: 4.5rem !important;
  }
  
  .ml-6,
  .mx-6 {
    margin-left: 4.5rem !important;
  }
  
  .m-7 {
    margin: 6rem !important;
  }
  
  .mt-7,
  .my-7 {
    margin-top: 6rem !important;
  }
  
  .mr-7,
  .mx-7 {
    margin-right: 6rem !important;
  }
  
  .mb-7,
  .my-7 {
    margin-bottom: 6rem !important;
  }
  
  .ml-7,
  .mx-7 {
    margin-left: 6rem !important;
  }
  
  .m-8 {
    margin: 8rem !important;
  }
  
  .mt-8,
  .my-8 {
    margin-top: 8rem !important;
  }
  
  .mr-8,
  .mx-8 {
    margin-right: 8rem !important;
  }
  
  .mb-8,
  .my-8 {
    margin-bottom: 8rem !important;
  }
  
  .ml-8,
  .mx-8 {
    margin-left: 8rem !important;
  }
  
  .m-9 {
    margin: 10rem !important;
  }
  
  .mt-9,
  .my-9 {
    margin-top: 10rem !important;
  }
  
  .mr-9,
  .mx-9 {
    margin-right: 10rem !important;
  }
  
  .mb-9,
  .my-9 {
    margin-bottom: 10rem !important;
  }
  
  .ml-9,
  .mx-9 {
    margin-left: 10rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  
  .px-4-1 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-top: 15rem;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  
  .p--9 {
    padding: -10rem !important;
  }
  
  .pt--9,
  .py--9 {
    padding-top: -10rem !important;
  }
  
  .pr--9,
  .px--9 {
    padding-right: -10rem !important;
  }
  
  .pb--9,
  .py--9 {
    padding-bottom: -10rem !important;
  }
  
  .pl--9,
  .px--9 {
    padding-left: -10rem !important;
  }
  
  .p--8 {
    padding: -8rem !important;
  }
  
  .pt--8,
  .py--8 {
    padding-top: -8rem !important;
  }
  
  .pr--8,
  .px--8 {
    padding-right: -8rem !important;
  }
  
  .pb--8,
  .py--8 {
    padding-bottom: -8rem !important;
  }
  
  .pl--8,
  .px--8 {
    padding-left: -8rem !important;
  }
  
  .p--7 {
    padding: -6rem !important;
  }
  
  .pt--7,
  .py--7 {
    padding-top: -6rem !important;
  }
  
  .pr--7,
  .px--7 {
    padding-right: -6rem !important;
  }
  
  .pb--7,
  .py--7 {
    padding-bottom: -6rem !important;
  }
  
  .pl--7,
  .px--7 {
    padding-left: -6rem !important;
  }
  
  .p--6 {
    padding: -4.5rem !important;
  }
  
  .pt--6,
  .py--6 {
    padding-top: -4.5rem !important;
  }
  
  .pr--6,
  .px--6 {
    padding-right: -4.5rem !important;
  }
  
  .pb--6,
  .py--6 {
    padding-bottom: -4.5rem !important;
  }
  
  .pl--6,
  .px--6 {
    padding-left: -4.5rem !important;
  }
  
  .p--5 {
    padding: -3rem !important;
  }
  
  .pt--5,
  .py--5 {
    padding-top: -3rem !important;
  }
  
  .pr--5,
  .px--5 {
    padding-right: -3rem !important;
  }
  
  .pb--5,
  .py--5 {
    padding-bottom: -3rem !important;
  }
  
  .pl--5,
  .px--5 {
    padding-left: -3rem !important;
  }
  
  .p--4 {
    padding: -1.5rem !important;
  }
  
  .pt--4,
  .py--4 {
    padding-top: -1.5rem !important;
  }
  
  .pr--4,
  .px--4 {
    padding-right: -1.5rem !important;
  }
  
  .pb--4,
  .py--4 {
    padding-bottom: -1.5rem !important;
  }
  
  .pl--4,
  .px--4 {
    padding-left: -1.5rem !important;
  }
  
  .p--3 {
    padding: -1rem !important;
  }
  
  .pt--3,
  .py--3 {
    padding-top: -1rem !important;
  }
  
  .pr--3,
  .px--3 {
    padding-right: -1rem !important;
  }
  
  .pb--3,
  .py--3 {
    padding-bottom: -1rem !important;
  }
  
  .pl--3,
  .px--3 {
    padding-left: -1rem !important;
  }
  
  .p--2 {
    padding: -0.5rem !important;
  }
  
  .pt--2,
  .py--2 {
    padding-top: -0.5rem !important;
  }
  
  .pr--2,
  .px--2 {
    padding-right: -0.5rem !important;
  }
  
  .pb--2,
  .py--2 {
    padding-bottom: -0.5rem !important;
  }
  
  .pl--2,
  .px--2 {
    padding-left: -0.5rem !important;
  }
  
  .p--1 {
    padding: -0.25rem !important;
  }
  
  .pt--1,
  .py--1 {
    padding-top: -0.25rem !important;
  }
  
  .pr--1,
  .px--1 {
    padding-right: -0.25rem !important;
  }
  
  .pb--1,
  .py--1 {
    padding-bottom: -0.25rem !important;
  }
  
  .pl--1,
  .px--1 {
    padding-left: -0.25rem !important;
  }
  
  .p-6 {
    padding: 4.5rem !important;
  }
  
  .pt-6,
  .py-6 {
    padding-top: 4.5rem !important;
  }
  
  .pr-6,
  .px-6 {
    padding-right: 4.5rem !important;
  }
  
  .pb-6,
  .py-6 {
    padding-bottom: 4.5rem !important;
  }
  
  .pl-6,
  .px-6 {
    padding-left: 4.5rem !important;
  }
  
  .p-7 {
    padding: 6rem !important;
  }
  
  .pt-7,
  .py-7 {
    padding-top: 6rem !important;
  }
  
  .pr-7,
  .px-7 {
    padding-right: 6rem !important;
  }
  
  .pb-7,
  .py-7 {
    padding-bottom: 6rem !important;
  }
  
  .pl-7,
  .px-7 {
    padding-left: 6rem !important;
  }
  
  .p-8 {
    padding: 8rem !important;
  }
  
  .pt-8,
  .py-8 {
    padding-top: 8rem !important;
  }
  
  .pr-8,
  .px-8 {
    padding-right: 8rem !important;
  }
  
  .pb-8,
  .py-8 {
    padding-bottom: 8rem !important;
  }
  
  .pl-8,
  .px-8 {
    padding-left: 8rem !important;
  }
  
  .p-9 {
    padding: 10rem !important;
  }
  
  .pt-9,
  .py-9 {
    padding-top: 10rem !important;
  }
  
  .pr-9,
  .px-9 {
    padding-right: 10rem !important;
  }
  
  .pb-9,
  .py-9 {
    padding-bottom: 10rem !important;
  }
  
  .pl-9,
  .px-9 {
    padding-left: 10rem !important;
  }